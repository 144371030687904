<template>
    <b-row>
        
        <b-col class="text-center">
         
            
        </b-col>
        

        
    </b-row>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
