<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">

        <div>
          <!-- main menu header-->
          <b-row class="nav navbar-nav navbar-nav-logo align-items-center">

            <!-- Logo & Text -->
            <b-col class="nav-item mr-auto test">
              <b-link class="navbar-brand" to="/">
                <!-- <span class="brand-logo">
                  <b-img :src="appLogoImage" alt="logo" />
                </span> -->
                <span class="brand-text" style="padding-left: 10px;">
                  <b-img v-if="skin === 'dark'" src="@/@core/assets/images/cpacer_light.svg" alt="logo" />
                  <b-img v-else src="@/@core/assets/images/cpacer.svg" alt="logo" />
                </span>
              </b-link>
            </b-col>
          </b-row>

          <div class="navbar-header expanded" v-if="isShowBlock('balance')">
            <b-row>
              <b-col md="12" lg="12" cols="12" style="padding: 3px">
                <b-card class="b-card-secondary" no-body>
                  <b-card-sub-title>
                    Баланс
                  </b-card-sub-title>
                  <b-card-body>
                    <b-card-text class="fs-24">
                      {{User.self.balance.formatMoney('','','')}} ₽
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" lg="6" cols="12" style="padding: 3px">
                <b-card class="b-card-secondary" no-body>
                  <b-card-sub-title>
                    Заявки
                  </b-card-sub-title>
                  <b-card-body>
                    <b-card-text>
                      {{User.self.orders}} шт
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="6" lg="6" cols="12" style="padding: 3px">
                <b-card class="b-card-secondary" no-body>
                  <b-card-sub-title>
                    Уровень
                  </b-card-sub-title>
                  <b-card-body>
                    <b-card-text class="d-flex align-items-center">
                      <div class="icon-star"></div>
                      <span style="white-space: nowrap;">1 <span class="text-muted">из 10</span></span>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>

      </slot>
    </div>
    <!-- / main menu header-->

    <hr v-if="isShowBlock('balance')" class="light" style="margin: calc(4px + 0.5rem) 16px 14px 16px">

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="menuItemsWithRouter" class="navigation navigation-main" />
    </vue-perfect-scrollbar>

  </div>
</template>

<script>
import navMenuItemsAccount from '@/navigation/vertical/account'
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

import User from "@/modules/user/"

export default {
  data() {
    return {
      User
    }
  },
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {

    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      navMenuItemsAccount,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,
      isDark,

      // App Name
      appName,
      appLogoImage,
    }
  },

  methods: {
    getCurrentRoute() {
      if (this.$route.meta.group == 'administrator') return { name: 'admin-users-list' }
      else return "/";
    },
    isShowBlock(type) {
      if (type) {
        if (type === 'balance') {
          if (this.$route.name.includes('user-account') || this.$route.name.includes('admin-')) return false
        }
      }
      return true;
    }
  },
  computed: {
    menuItemsWithRouter() {
      if (this.$route.name.includes('user-account')) return this.navMenuItemsAccount;
      else {
        return this.navMenuItems;
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
